import React from 'react';
import styled from 'styled-components';
import { Grid } from '@catalogo/theme-grid';
import HasNoProductsError from '@catalogo/ui-americanas-desktop-has-no-products-error';

const NotFound = ({ location }) => {
  const term = location?.pathname?.split('/').join(' ').trim() || 'sua busca';

  return (
    <GridUI>
      <HasNoProductsError content={term} />
    </GridUI>
  );
};

const GridUI = styled(Grid)`
  padding: 0 12px;
  max-width: 1270px;
  margin: 0 auto;
`;

export default NotFound;
